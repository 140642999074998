import React from 'react'
import {navigateTo} from 'gatsby-link'
import Gallery from 'react-photo-gallery'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import Button from '../components/button'

import Background from '../images/russellst/078dark.jpg'

import img009 from '../images/russellst/009.jpg'
import img012 from '../images/russellst/012.jpg'
import img027 from '../images/russellst/027.jpg'
import img036 from '../images/russellst/036.jpg'
import img044 from '../images/russellst/044.jpg'
import img048 from '../images/russellst/048.jpg'
import img051 from '../images/russellst/051.jpg'
import img056 from '../images/russellst/056.jpg'
import img061 from '../images/russellst/061.jpg'
import img065 from '../images/russellst/065.jpg'
import img069 from '../images/russellst/069.jpg'
import img078 from '../images/russellst/078.jpg'
import img083 from '../images/russellst/083.jpg'
import img094 from '../images/russellst/094.jpg'
import img101 from '../images/russellst/101.jpg'
import img104 from '../images/russellst/104.jpg'
import img121 from '../images/russellst/121.jpg'
import img1 from '../images/russellst/1.jpg'
import img4 from '../images/russellst/4.jpg'
import img9 from '../images/russellst/9.jpg'
import img11 from '../images/russellst/11.jpg'
import img22 from '../images/russellst/22.jpg'
import img24 from '../images/russellst/24.jpg'
import img25 from '../images/russellst/25.jpg'


const photos = [

  {
    src: img009,
    width: 960,
    height: 640
  },
  {
    src: img012,
    width: 960,
    height: 640
  },
  {
    src: img027,
    width: 960,
    height: 640
  },
  {
    src: img036,
    width: 960,
    height: 640
  },
  {
    src: img044,
    width: 960,
    height: 640
  },
  {
    src: img048,
    width: 960,
    height: 640
  },
  {
    src: img051,
    width: 960,
    height: 640
  },
  {
    src: img056,
    width: 960,
    height: 640
  },
  {
    src: img061,
    width: 960,
    height: 640
  },
  {
    src: img065,
    width: 960,
    height: 640
  },
  {
    src: img069,
    width: 960,
    height: 640
  },
  {
    src: img078,
    width: 960,
    height: 640
  },
  {
    src: img083,
    width: 960,
    height: 640
  },
  {
    src: img094,
    width: 960,
    height: 640
  },
  {
    src: img101,
    width: 960,
    height: 640
  },
  {
    src: img104,
    width: 960,
    height: 640
  },
  {
    src: img121,
    width: 960,
    height: 640
  },
  {
    src: img1,
    width: 960,
    height: 1441
  },
  {
    src: img4,
    width: 960,
    height: 1441
  },
  {
    src: img9,
    width: 960,
    height: 640
  },
  {
    src: img11,
    width: 960,
    height: 640
  },
  {
    src: img22,
    width: 960,
    height: 1441
  },
  {
    src: img24,
    width: 960,
    height: 640
  },
  {
    src: img25,
    width: 960,
    height: 640
  }
]

const NelsonStreet = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Background}
      />
      <CardBox title="Russell Street, Abbotsford" noSpacer center>
        <Card>
          <Gallery photos={photos}/>
          <br/>
          <Button dark onClick={() => navigateTo('/projects')}>Back to projects</Button>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default NelsonStreet
